import styled from "styled-components";

import Navbar from "../components/Navbar";
import Banner from "../components/Banner";
import Buildings from "../components/Buildings";
import BuildingAbout from "../components/Buildingaboutus";
import OurAboutUs from "../components/Ouraboutus";
import Ourservicepage from "../components/Ourservicepage";
import BannerImage from "../components/BannerImage";
import Ourmenbers from "../components/Ourmenbers";
import Testimonials from "../components/Testimonials";
import Homecontect from "../components/Homecontect";
import Footer from "../components/Footer";

const Home = () => {
  return (
    <>
      <main className="container">
        <Navbar />
        <Banner />
        <BuildingAbout />
        <Buildings />
        <OurAboutUs />
        <Ourservicepage />
        <BannerImage />
        <Ourmenbers />
        <Testimonials />
        <Homecontect />
        <Footer />
        <Copyright>© 2024 All Rights Reserved. <a href="https://npng.in/">NPNG</a></Copyright>

      </main>
    </>
  );
};

export default Home;

const Main = styled.div`
  width: 100%;
  max-width: 100%;
`;
const Copyright = styled.p`
  font-size: 14px;
  text-align: center;
 background-color: black;
  color: white;
  padding: 20px 0;
  margin: 0;

  @media (max-width: 375px) {
    font-size: 12px;
  }
`;